import utils from "@/utilities/Utils";
import { mapData } from "@/utilities/DataMapping";

export interface ICountry {
    id: string;
    description: string;
    isDeliveryCountry: boolean;
    defaultPrinterID: string;
    isHidden: boolean;
    estimatedDeliveryTime: string;
    timezone: string;
}

export class Country implements ICountry {

    constructor(data?: ICountry) {
        if (data) this.update(data);
    }

    update(data: ICountry): void {
        mapData(data, { root: () => this });
    }

    id: string = utils.emptyGuidValue;
    description: string = "";
    isDeliveryCountry: boolean = false;
    defaultPrinterID: string = utils.emptyGuidValue;
    isHidden: boolean = false;
    estimatedDeliveryTime: string = "";
    timezone: string = "";

    get isNew(): boolean {
        return utils.isEmptyId(this.id);
    }
}
