import { ICountry, Country } from "../../Country";
import { mapData } from "@/utilities/DataMapping";

export interface ICountryVM {
    country: ICountry;
    defaultPrinter: string;
    timeZone: string;
}

export class CountryVM implements ICountryVM {

    constructor(data?: ICountryVM) {
        if (data) this.update(data);
    }

    update(data: ICountryVM): void {
        mapData(data, { 
            root: () => this,
            country: () => new Country()
        });
    }

    country: Country = new Country();
    defaultPrinter: string = "";
    timeZone: string = "";
}