



























































import Vue from "vue";
import Component from "vue-class-component";
import ApiButton from "@/vue/components/ApiButton.vue";
import apiClient from "@/utilities/ApiClient";
import utils from "@/utilities/Utils";
import { Country, ICountry } from "@/model/Country";
import { ILookupItem, LookupItem } from "@/model/LookupItem";
import { IPrinterVM } from "@/model/Api/VM/PrinterVM";
import { ISaveResponse } from "@/model/ISaveResponse";
import { Ref } from "vue-property-decorator";
import { VForm } from "@/vForm";
import * as toastr from "toastr";

@Component({ components: { ApiButton } })

export default class CountryDialogue extends Vue {

    //
    // -- properties
    //

    @Ref("countryForm") private readonly countryForm!: VForm;  

    private showDialogue: boolean = false;
    private country: Country = new Country();
    private availablePrinters: Array<IPrinterVM> = [];
    private timezones: Array<ILookupItem> = [];

    //
    // -- methods
    //

    add(): void {
        this.reset();
        this.loadTimeZones();
        this.showDialogue = true;
    }

    async edit(id: string): Promise<void> {
        this.reset();
        this.loadTimeZones();
        const countryData: ICountry = await apiClient.get(`api/country/Load?id=${id}`);
        this.country.update(countryData);
        this.loadPrinters();
        this.showDialogue = true;
    }

    async loadPrinters(): Promise<void> {
        this.availablePrinters = [];
        const response = await apiClient.get("/api/printer/loadAll");
        this.availablePrinters.push(...response);
    }

    private async loadTimeZones(): Promise<void> {
        this.timezones = [];
        const response = await apiClient.get("/api/country/timezoneOptions");
        this.timezones.push(...response.map((i: ILookupItem) => new LookupItem(i)));
    }

    private reset() {
        utils.resetObject(this.country);
        this.countryForm?.resetValidation();
        this.availablePrinters = [];
        this.timezones = [];
    }

    private async save() {
        if (!this.countryForm.validate()) {
            toastr.warning("Please fix highlighted issues", "Cannot Save");
            return;
        }
        const response: ISaveResponse = await apiClient.post("/api/country/save", this.country, "country-save");
        if (response.isSuccess) {
            toastr.success("Saved");
            this.$emit("refresh");    
            this.showDialogue = false;
        } else {
            toastr.warning("Failed to Save");
        }
    }
}
