






























import Vue from "vue";
import Component from "vue-class-component";
import ApiButton from "@/vue/components/ApiButton.vue";
import apiClient from "@/utilities/ApiClient";
import CountryDialogue from "@/vue/components/dialogues/CountryDialogue.vue";
import utils from "@/utilities/Utils";
import { CountryVM, ICountryVM } from "@/model/Api/VM/CountryVM";
import { SearchParameters } from "@/model/Api/SearchParams/SearchParameters";
import { StateChanger } from "vue-infinite-loading";
import { Watch } from "vue-property-decorator";

@Component({
    components: { 
        ApiButton,
        CountryDialogue
    }
})

export default class Countries extends Vue {
    
    //
    // -- properties
    //

    private countryHeaders = [
        { text: "Description", value: "country.description", sortable: true },
        { text: "Estimated Delivery Time (Days)", value: "country.estimatedDeliveryTime", align: "center", sortable: false },
        { text: "Time Zone Info", value: "timeZone", sortable: false },
        { text: "Delivery Country?", value: "country.isDeliveryCountry", align: "center", sortable: true },
        { text: "Default Printer", value: "defaultPrinter", sortable: false },
        { text: "Is Hidden?", value: "country.isHidden", align: "center", sortable: true },
    ];

    private infiniteId: number = + new Date();
    private searchParameters = new SearchParameters();
    private countryList: Array<CountryVM> = [];

    //
    // -- Watchers
    //

    private debouncedRefreshSearch = utils.debounce(this.refreshSearch, 200);

    @Watch("searchParameters.searchText")
    private onSearchTextChanged() {
        this.debouncedRefreshSearch();
    }

    //
    // -- methods
    //

    addNew(): void {
        const dialog: CountryDialogue = this.$refs.countryDialogue as CountryDialogue;
        dialog.add();
    }

    edit(country: ICountryVM): void {
        const dialog: CountryDialogue = this.$refs.countryDialogue as CountryDialogue;
        dialog.edit(country.country.id);
    }

    refreshSearch(): void {
        this.countryList = [];
        this.searchParameters.pageNumber = 1;
        this.infiniteId += 1;
    }

    async infiniteLoadingHandler(stateChanger: StateChanger): Promise<void> {
        const response = await apiClient.post("/api/country/search", this.searchParameters);
        if (response.list.length) {
            this.countryList.push(...response.list.map((c: ICountryVM) => new CountryVM(c)));
            this.searchParameters.pageNumber += 1;
            stateChanger.loaded();
        }
        else {
            stateChanger.complete();
        }         
    }
}
